



































































































import Vue from "vue";
import { AuthLogin } from "@/interfaces/auth/auth_login";
import { get_password_validator_regex } from "@/utils/global";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  AUTH_LOADING,
  GET_USER_DETAILS,
  LOGIN_USER,
  USER_STATE
} from "@/store/modules/auth/constants";
import {
  GET_ROLE_BY_NAME,
  INIT_COMMON_DATA
} from "@/store/modules/common/constants";
import { INIT_RECRUITER } from "@/store/modules/recruiter/constants";
import {
  email,
  maxLength,
  minLength,
  required
} from "vuelidate/lib/validators";
import { TranslateResult } from "vue-i18n";
import { UserState } from "@/store/modules/auth/interfaces";
import router from "@/router";
import { INIT_CANDIDATE } from "@/store/modules/candidates/constants";
import { ROOT_ERROR } from "@/store/modules/root/constants";
export default Vue.extend({
  name: "AuthLogin",
  data(): AuthLogin {
    return {
      login_form: {
        email: "",
        password: "",
        remember_me: true
      },
      show_password: false
    };
  },
  computed: {
    ...mapGetters("auth", {
      user_data: GET_USER_DETAILS,
      auth_loading: AUTH_LOADING,
      get_user_state: USER_STATE
    }),
    email_field_errors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.login_form.email?.$dirty) return errors;
      !this.$v.login_form.email?.required &&
        errors.push(this.$t("rules.required"));
      !this.$v.login_form.email?.email && errors.push(this.$t("rules.email"));
      return errors;
    },
    password_field_errors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.login_form.password?.$dirty) return errors;
      !this.$v.login_form.password?.required &&
        errors.push(this.$t("rules.required"));
      !this.$v.login_form.password?.min &&
        errors.push(this.$t("rules.password.min"));
      !this.$v.login_form.password?.max &&
        errors.push(this.$t("rules.password.max"));
      !this.$v.login_form.password?.valid &&
        errors.push(this.$t("rules.password.combine"));
      return errors;
    },
    ...mapGetters("common", {
      get_role_by_name: GET_ROLE_BY_NAME
    })
  },
  methods: {
    ...mapMutations({
      root_error: ROOT_ERROR
    }),
    ...mapMutations("auth", {
      set_user_state: USER_STATE,
      set_auth_loading: AUTH_LOADING
    }),
    ...mapActions("auth", {
      login_user: LOGIN_USER
    }),
    ...mapActions("recruiter", {
      init_recruiter: INIT_RECRUITER
    }),
    ...mapActions("candidate", {
      init_candidate: INIT_CANDIDATE
    }),
    ...mapActions("common", {
      init_common_data: INIT_COMMON_DATA
    }),
    /**
     * FUnction to initialize reset password state
     */
    async reset_password_init() {
      this.set_user_state(UserState.RESET_PASSWORD);
      await router.push("reset-password");
    },
    async submit_login_form() {
      this.$v.$touch();
      // Validating login form
      if (!this.$v.$invalid) {
        const login_payload = { ...this.login_form };
        // Login user
        const login_response = await this.login_user(login_payload);
        // If login successful
        if (login_response) {
          this.$v.login_form?.$reset(); // Reset form
          // Initializing common data => roles, industries
          const init_common = await this.init_common_data();
          if (!init_common) {
            this.$v.login_form?.$reset();
            this.root_error(this.$t("errors.internal").toString());
            this.set_auth_loading(false);
            return;
          }
          const recruiter_role = this.get_role_by_name("recruiter");
          const candidate_role = this.get_role_by_name("individual");
          const recruiter_admin = this.get_role_by_name("recruiter admin");
          const user = this.user_data;
          // If email not confirmed => navigate to verify-email page
          if (!user.is_email_confirmed) {
            this.set_user_state(UserState.EMAIL_VERIFICATION_PENDING);
            if (
              this.$route.query.redirect &&
              this.$route.query.redirect.includes("verify-email")
            )
              await router.push(this.$route.query.redirect);
            else await router.push("/auth/verify-email");
          }
          // // If onboarding not confirmed => navigate to onboarding page
          // else if (!user.is_onboarding_completed) {
          //   this.set_user_state(UserState.PENDING_VERIFICATION);
          //   await router.push("/auth/pending-verification");
          // }
          else if (!user.is_account_confirmed) {
            this.set_user_state(UserState.PENDING_VERIFICATION);
            await router.push("/auth/pending-verification");
          }
          // If user role is recruiter => init recruiter data & navigate to dashboard
          else if (recruiter_role.id === user.role_id)
            await this.fetch_init_recruiter();
          else if (recruiter_admin.id === user.role_id) {
            await this.fetch_init_recruiter();
            this.set_user_state(UserState.RECRUITER_ADMIN);
          } // If user role is candidate => init candidate data & navigate to dashboard
          else if (candidate_role.id === user.role_id)
            await this.fetch_init_candidate();
          this.set_auth_loading(false);
        }
      }
    },
    /**
     * Function to initialize recruiter data
     */
    async fetch_init_recruiter() {
      const recruiter_initialize = await this.init_recruiter();
      // If recruiter not initialized successfully => navigate to error page
      if (!recruiter_initialize) {
        this.set_user_state(UserState.ROOT_ERROR);
        await router.push("/error");
      } else {
        this.set_user_state(UserState.RECRUITER);
        // If redirect URL exist in query => navigate to redirected url
        if (this.$route.query.redirect)
          await router.push(this.$route.query.redirect);
        // If route instead of recruiter route
        // Navigate to recruiter dashboard
        else if (!this.$route.meta.user_state.includes(UserState.RECRUITER)) {
          await router.push("/recruiter");
        }
      }
    },
    /**
     * Function to initialize candidate data
     */
    async fetch_init_candidate() {
      const candidate_initialize = await this.init_candidate();
      // If candidate not initialized successfully => navigate to error page
      if (!candidate_initialize) {
        this.set_user_state(UserState.ROOT_ERROR);
        await router.push("/error");
      } else if (this.get_user_state !== UserState.CANDIDATE_PROFILE_PENDING) {
        this.set_user_state(UserState.CANDIDATE);
        // If redirect URL exist in query => navigate to redirected url
        if (this.$route.query.redirect)
          await router.push(this.$route.query.redirect);
        // If route instead of candidate route
        // Navigate to candidate dashboard
        else if (!this.$route.meta.user_state.includes(UserState.CANDIDATE)) {
          await router.push("/candidate");
        }
      }
    }
  },
  // Form validations
  validations() {
    return {
      login_form: {
        email: {
          required,
          email: email
        },
        password: {
          required,
          min: minLength(8),
          max: maxLength(16),
          valid: (value: string) => get_password_validator_regex().test(value)
        },
        remember_me: {}
      }
    };
  }
});
